@import 'Styles/includes';

.MemberModule {
    $root: &;

    padding-top: 16px;
    margin: 40px 0;

    @include media(M) {
        margin: 60px 0;
    }

    @include media(L) {
        margin: 80px 0;
    }

    &__Wrap {
        @include wrap;
        position: relative;
    }

    &__Title {
        @include textstyle(h2);

        margin: 0 auto;
        text-align: center;
        margin-bottom: 24px;

        @include media(M) {
            margin-bottom: 48px;
        }
    }

    &__Steps {
        @extend %grid__list;
    }

    &__Step {
        @extend %grid__item;
        @extend %grid__item--third;

        padding: 0 16px 16px;
        text-align: center;

        @include media(M) {
            padding: 0 8px 24px;
        }

        @include media(L) {
            padding: 0 48px 28px;
        }
    }

    &__StepNumber {
        position: relative;
        margin: 8px 0;
        height: 46px;
        display: block;
        overflow: hidden;

        @include media(M) {
            margin: 14px 0 35px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__StepTitle {
        @include textstyle(h4);
        margin-bottom: 14px;

        @include media(M) {
            margin-bottom: 24px;
        }
    }

    &__StepText {
        @include textstyle(body-regular);

        max-width: 320px;
        margin: 0 auto;
    }

    &__Entrances {
        margin-top: -32px;

        @include media(M) {
            margin-top: -52px;
        }

        @include media(L) {
            margin-top: -72px;
        }
    }
}
