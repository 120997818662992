@import 'Styles/includes';

.LinkModule {
    $root: &;

    &--FullWidth {
        background: white;
    }

    &--ContentWidth {
        margin: 32px 0;

        @include media(M) {
            margin: 48px 0;
        }
    }

    &__Wrap {
        @include wrap;

        #{$root}--FullWidth & {
            padding: 32px 0;

            @include media(M) {
                padding: 48px 0;
            }
        }
    }

    &__Content {
        margin: 0 auto;

        #{$root}--One & {
            max-width: map-get($maxwidths, article);
        }

        #{$root}--Two & {
            max-width: 832px;
        }

        #{$root}--ContentWidth & {
            background: white;
            padding: 32px;

            @include media(M) {
                padding: 48px;
            }
        }
    }

    &__Title {
        max-width: map-get($maxwidths, article);

        #{$root}--SmallTitle & {
            @include textstyle(h4);
        }

        #{$root}--LargeTitle & {
            @include textstyle(h2);

        }
    }

    &__Text {
        @include textstyle(body-regular);

        max-width: map-get($maxwidths, article);
        margin-top: 16px;
    }

    &__List {
        @extend %grid__list;

        margin-top: 32px;
    }

    &__Item {
        @extend %grid__item;

        display: flex;

        #{$root}--Two & {
            @extend %grid__item--half;
        }

        #{$root}--Three & {
            @extend %grid__item--third;
        }
    }

    &__Button {
        @include button(secondary full);

        flex: 1 0 auto;
        align-items: center;
        display: flex;
    }
}
