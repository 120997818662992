@import 'Styles/includes';

.Hero {
    $root: &;

    background: white;

    &__Wrap {
        @include wrap;

        position: relative;
    }

    &__Columns {
        display: flex;
        flex-direction: column-reverse;

        @include media(ML) {
            flex-direction: row;
        }
    }

    &__Column {
        @include media(ML) {
            width: 50%;
        }

        &:first-child {
            min-height: 140px;
            padding: 32px 0;

            @include media(ML) {
                min-height: 300px;
                padding: 80px 20px 80px 0;
                padding-right: 20px;
                display: flex;
                flex: 0 0 auto;
                min-width: 50%;
                justify-content: center;
                flex-direction: column;
            }

            @include media(L) {
                min-height: 400px;
                padding: 112px 90px 112px 0;
            }

            #{$root}--Logo & {
                // width: calc(100% - (200px + 20px + 20px));

                @include media(M) {
                    width: calc(100% - (200px + 40px + 40px));
                }

                @include media(L) {
                    width: calc(100% - (220px + 80px + 80px));
                }

                @include media(XL) {
                    width: calc(100% - (320px + 140px + 140px));
                }
            }
        }
    }

    &__Title {
        @include textstyle(h1);
    }

    &__Text {
        @include textstyle(body-regular);

        margin-top: 30px;

        @include media(ML) {
            max-width: 400px;
        }
    }

    &__Link {
        @include button(primary);
        align-self: flex-start;
        min-width: 226px;
        margin-top: 40px;

        &:hover {
            color: white;
        }
    }

    &__Image {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        &::after {
            content: '';
            height: 0;
            display: block;
            padding-bottom: 100%;

            @include media(ML) {
                display: none;
            }

            #{$root}--Logo & {
                display: none;
            }
        }

        @include media(ML) {
            width: 50vw;
        }

        #{$root}--Logo & {
            max-width: (200px + 20px + 20px);
            margin: 0 auto;
            padding: 40px 20px 20px !important;

            @include media(M) {
                max-width: (200px + 40px + 40px);
                padding: 40px !important;
            }

            @include media(L) {
                max-width: (220px + 80px + 80px);
                padding: 80px !important;
            }

            @include media(XL) {
                max-width: (320px + 140px + 140px);
                padding: 140px !important;
            }
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            #{$root}--Logo & {
                position: relative;
            }
        }
    }
}
