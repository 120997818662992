@import 'Styles/includes';

.QuoteModule {
    $root: &;

    margin: 40px 0;

    @include media(M) {
        margin: 60px 0;
    }

    @include media(L) {
        margin: 80px 0;
    }

    &__Wrap {
        @include wrap;
        position: relative;

        @include media(M) {
            display: flex;
            align-items: flex-start;
        }
    }

    &__Image {
        position: relative;
        width: calc(100% - 24px);
        margin-right: 24px;
        margin-bottom: -40px;

        @include media(M) {
            width: 70%;
            margin-right: -100px;
            margin-bottom: 0;
        }

        @include media(L) {
            width: 65.5%;
            margin-right: -70px;
        }

        &::before {
            content: '';
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 55%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Quote {
        position: relative;
        background: white;
        padding: 24px;
        margin-left: 24px;
        text-align: center;

        &::before {
            content: '';
            line-height: 30px;
            display: block;
            width: 30px;
            height: 26px;
            object-fit: contain;
            background-image: url('#{$assetsPath}img/quote--salmon.svg');
            background-size: cover;
            background-repeat: none;
            position: absolute;
            top: -15px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        @include media(M) {
            width: 50%;
            margin: 30px 0 30px;
            align-self: flex-end;
        }

        @include media(L) {
            padding: 30px;
            margin-bottom: 53px;
            max-width: 498px;
        }

        p {
            @include textstyle(h4);
            padding: 0;
            margin: 0;
            position: relative;

            &:first-child {
                padding-bottom: 20px;
            }
        }

        cite {
            @include textstyle(body-small);
            line-height: 1.43;
            letter-spacing: 0.2px;
            color: $heading-color;
            display: flex;
            justify-content: flex-end;
        }
    }
}
