@import 'Styles/includes';

.NewsList {
    $root: &;

    margin: 40px 0;

    @include media(M) {
        margin: 60px 0;
    }

    @include media(L) {
        margin: 80px 0;
    }

    &__Wrap {
        @include wrap;
    }

    &__Header {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-bottom: 24px;
    }

    &__Title {
        @include textstyle(h2);

        width: 100%;
        flex: 1 1 auto;

        @include media(M) {
            width: auto;
        }
    }

    &__Link {
        @include button(tertiary);

        margin-top: 8px;
        margin-bottom: -8px;
        margin-left: -14px;
        white-space: nowrap;

        @include media(M) {
            margin-top: -7px;
            margin-left: 0;
        }
    }

    &__List {
        @extend %grid__list;
    }

    &__Item {
        @extend %grid__item;

        &--IsLarge {
            @extend %grid__item--half;
        }

        &--IsSmall {
            @extend %grid__item--third;
        }
    }
}
