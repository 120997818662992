@import 'Styles/includes';

.ImageBlock {
    $root: &;

    margin: 0;

    @include media(M) {
        margin: 60px 0;
    }

    @include media(L) {
        margin: 80px 0;
    }

    &__Wrap {
        /* Will be full width in mobile */
        @include wrap((d, SL));

        position: relative;
        padding: 50% 0 24px;
        width: 100%;

        @include media(M) {
            padding: 25% 32px 32px;
        }

        @include media(L) {
            padding: 15% 48px 48px;
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Card {
        margin: 0 $gutter;
        position: relative;
        padding: 24px;
        background-color: white;

        @include media(SL) {
            max-width: (map-get($maxwidths, SL));
            margin: 0 auto;
        }

        @include media(M) {
            max-width: 400px;
            margin: 0;
        }
    }

    &__Title {
        @include textstyle(h2);
    }

    &__Text {
        @include textstyle(body-regular);

        margin-top: 13px;
    }

    &__Link {
        @include button(primary);

        width: 100%;
        margin-top: 40px;

        &:hover {
            color: white;
        }
    }
}
