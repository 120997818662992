@import 'Styles/includes';

.CardHighlight {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 200px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        z-index: $z-Card;
    }

    &__Placeholder {
        display: flex;
        flex-direction: row;
        width: 100%;

        @include media(M) {
            flex-direction: column;
        }

        @include media(L) {
            flex-direction: row;
        }
    }

    &__Image {
        flex: 0 1 auto;
        width: 38%;
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 53.5%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        @include media(M) {
            width: 100%;
        }

        @include media(L) {
            width: 38%;
        }
    }

    &__Content {
        flex: 1 0 auto;
        width: 62%;
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: white;

        @include media(M) {
            width: 100%;
        }

        @include media(L) {
            width: 62%;
            padding: 30px;
        }
    }

    &__Title {
        @include textstyle(h4);

        position: relative;
        padding-right: 40px;
        word-break: break-word;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            right: 8px;
            width: 7px;
            height: 14px;
            background: url('#{$assetsPath}img/arrowRight.svg') no-repeat center center;
            background-size: contain;
            transition: transform $transition;
        }

        #{$root}:hover & {
            &::after {
                transform: translateX(8px);
            }
        }
    }

    &__Text {
        @include textstyle(body-regular);

        margin-top: 12px;
        color: $grey;
    }
}
